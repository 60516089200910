const Papa = require('papaparse')
import i18n from '../i18n'

export const exportCsv = (data, filename) => {
  const headers = ['user', 'date', 'imputationDate', 'accountingUnit', 'siteId', 'resultCenterId', 'family', 'amount', 'label', 'entryType']

  const csvVal = Papa.unparse(data, {
    header: false,
    columns: headers
  })
  
  const headersVal = Papa.unparse({ fields: headers.map(h => i18n.t(h)), data: [] })

  const result = headersVal + csvVal

  var encodedUri = encodeURI('data:text/csv,' + result)
  var link = document.createElement('a')
  link.setAttribute('href', encodedUri)
  link.setAttribute('download', filename + '.csv')
  document.body.appendChild(link) // Required for FF

  link.click() // This will download the data file named "my_data.csv".
}

export const importCsv = csvText => {
  return Papa.parse(csvText, { skipEmptyLines: true, header: true, transform: v => (v ? v : null) }).data
}
